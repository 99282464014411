const getFetchArgs = (url: string, options: RequestInit) => {
  let updatedUrl = url;
  const updatedOptions = { method: "GET", ...options };
  const metaToken = document.querySelector(
    'meta[name="csrf-token"]',
  ) as HTMLMetaElement;

  if (metaToken) {
    updatedOptions.headers = {
      ...updatedOptions.headers,
      "X-CSRF-Token": metaToken.content,
      "Content-Type": "application/json",
    };
  }

  if (updatedOptions.body) {
    if (updatedOptions.method === "GET") {
      const searchParams = new URLSearchParams(updatedOptions.body as string);
      updatedUrl = `${updatedUrl}?${searchParams.toString()}`;
      delete updatedOptions.body;
    } else {
      updatedOptions.body = JSON.stringify(updatedOptions.body);
    }
  }

  return [updatedUrl, updatedOptions] as const;
};

const fetcher = async (url: string, options?: Record<string, unknown>) => {
  const [fetchUrl, fetchOptions] = getFetchArgs(url, options || {});

  return fetch(fetchUrl, fetchOptions);
};

export default fetcher;
